body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --fantom-blue: #1969FF;
  --dark-grey: #707B8F;
}

#root {
  background-color: #0A162E;
}
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(/static/media/ProximaNova-Regular.ecef0fae.woff2) format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(/static/media/ProximaNova-Semibold.c8dbfaeb.woff2) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(/static/media/ProximaNova-Bold.571f1669.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(/static/media/ProximaNova-Black.cc650303.woff2) format('woff2');
  font-weight: 900;
}

.App {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 1588px;
  margin: auto;
}

.App-logo {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App-header {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 900;
  line-height: 39px;

  /* identical to box height */
  text-align: center;

  /* Semiwhite */
  color: #EFF3FB;
}

.App-header h1 {
  font-size: 32px;
  font-weight: 900;
  margin: 0;
}

.headerContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: 50px;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.headerContainer>a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.App-link {
  color: #61dafb;
}

.Template-selector h2 {
  margin: 0;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */
  text-align: center;

  color: #EFF3FB;
}

.Template-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 402px);
  grid-auto-rows: 1fr;
  grid-gap: 50px;
  width: 100%;
  margin-top: 75px;
  -webkit-justify-content: center;
          justify-content: center;
}

.Template-card {
  background: #0E1D37;
  border-radius: 8px;
  padding: 24px;
  width: 354px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

h3 {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #EFF3FB;
}

.Template-text {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #B7BECB;
}

.Template-threshold {
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 21px;
  color: #B7BECB;
  margin-top: 22px;
}

.Template-threshold span {
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 21px;
  color: #B7BECB;
  color: cyan;
}

.Template-select-button {
  background: #1969FF;
  border-radius: 8px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  padding: 15px;
  border: none;
  margin-top: 70px;
  cursor: pointer;
}

.Template-select-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.Template-content {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Home-page,
.Proposal-page {
  width: 100%;
}

.Proposal-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 125px;
}

.Proposal-container>h2 {
  display: none;
  text-align: center;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #EFF3FB;
}

.Proposal-description-container,
.Proposal-threshold-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 60px;
}

.Proposal-description-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Proposal-description-row>label,
.Proposal-threshold-row>label,
.Proposal-period-container>label {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;

  /* grey */
  color: #B7BECB;
}

.Proposal-description-row>input,
.Proposal-option-container>input {
  background: #202F49;
  border-radius: 8px;
  border: none;
  padding: 16px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height, or 133% */

  /* white */
  color: #FFFFFF;
  outline: none;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Proposal-input-footer>span {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;

  /* identical to box height, or 133% */
  text-align: right;

  /* dark grey */
  color: var(--dark-grey);
  text-align: right;
}

.Proposal-description-row>textarea {
  background: #202F49;
  border-radius: 8px;
  height: 170px;
  padding: 16px;
  border: none;
  resize: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  outline: none;
}

.Proposal-description-row>input::-webkit-input-placeholder, .Proposal-option-container>input::-webkit-input-placeholder, .Proposal-description-row>textarea::-webkit-input-placeholder, .Proposal-period-container>input::-webkit-input-placeholder, .Proposal-period-container input::-webkit-input-placeholder {
  color: var(--dark-grey);
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: var(--dark-grey);
}

.Proposal-description-row>input:-ms-input-placeholder, .Proposal-option-container>input:-ms-input-placeholder, .Proposal-description-row>textarea:-ms-input-placeholder, .Proposal-period-container>input:-ms-input-placeholder, .Proposal-period-container input:-ms-input-placeholder {
  color: var(--dark-grey);
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: var(--dark-grey);
}

.Proposal-description-row>input::placeholder,
.Proposal-option-container>input::placeholder,
.Proposal-description-row>textarea::placeholder,
.Proposal-period-container>input::placeholder,
.Proposal-period-container input::placeholder {
  color: var(--dark-grey);
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: var(--dark-grey);
}

button.Proposal-vote-option-add-btn {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;

  /* identical to box height */
  text-align: center;

  /* Fantom blue */
  color: var(--fantom-blue);
  text-align: left;
  margin: 0;
  text-decoration: none;
  background: none;
  cursor: pointer;
  border: none;
  display: inline-block;
  padding: 0;
}

.Proposal-period-container input {
  background: #202F49;
  border-radius: 8px;
  border: none;
  width: 32px;
  height: 32px;
  padding: 16px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height, or 133% */

  /* white */
  color: #FFFFFF;
  text-align: center;
  outline: none;
}

.Proposal-time-selector {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.Proposal-time-selector>div {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height, or 133% */
  text-align: right;

  /* dark grey */
  color: #707B8F;
  margin-left: 12px;
}

.Proposal-period-threshold-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 30px;
}

.Proposal-period-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 125px;
}

label.Proposal-time-range {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  /* identical to box height */

  color: #707B8F;
  line-height: 32px;
}

.Proposal-create-btn {
  background: var(--fantom-blue);
  border-radius: 8px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;

  /* identical to box height */
  text-align: center;

  /* white */
  color: #FFFFFF;
  padding: 10px 90px;
  border: none;
  margin: auto;
  margin-top: 70px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-width: 310px;
}

.Proposal-create-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.Proposal-threshold-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Proposal-fee {
  margin: auto;
}

.rc-slider-rail {
  background: #202F49;
  border-radius: 4px;
  height: 8px;
}

.rc-slider-track {
  background: #1969FF;
  border-radius: 4px;
  height: 8px;
}

.rc-slider-handle {
  background: #1969FF;
  width: 24px;
  height: 24px;
  border: none;
  margin-top: -8px;

  touch-action: manipulation;
}

.rc-slider-step {
  height: 8px;
}

.rc-slider-tooltip-inner {
  background: var(--fantom-blue);
  border-radius: 3px;
  width: 42px;
  height: 24px;
  box-shadow: none;
}

.rc-slider-tooltip-content {
  margin-top: 15px;
}

.rc-slider-tooltip-placement-bottom .rc-slider-tooltip-arrow {
  top: 7px;
  left: 50%;
  margin-left: -6px;
  border-width: 0 6px 8px;
  border-bottom-color: var(--fantom-blue);
}

.rc-slider {
  height: 70px;
  touch-action: manipulation;
}

.rc-slider-dot {
  display: none;
}

.rc-slider-mark>.rc-slider-mark-text {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #B7BECB;
}

.rc-slider-mark>.rc-slider-mark-text:first-child {
  -webkit-transform: none !important;
          transform: none !important;
}

.rc-slider-mark>.rc-slider-mark-text:last-child {
  -webkit-transform: translateX(-100%) !important;
          transform: translateX(-100%) !important;
}

.rc-slider-tooltip-hidden {
  display: block;
}

.rc-slider-mark {
  top: 28px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #B7BECB;
}

.Modal-close-btn {
  float: right;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: 36px;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #0E1D37;
  border-radius: 8px;
  max-width: 352px;
  min-height: 272px;
  padding: 24px;
  text-align: center;
}

.Modal-proposal-success {
  margin: 40px 0;
  max-width: 80px;
  width: 100%;
  max-height: 80px;
  height: 100%;
}

.Modal p,
.Mobile-modal p {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 140%;

  /* or 39px */
  text-align: center;

  /* text-active */
  color: #EFF3FB;
  margin-top: 0;
  margin-bottom: 36px;
  overflow-wrap: break-word;
}

.Proposal-option-container {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 14px;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Proposal-option-container:last-child {
  margin-bottom: 0 !important;
}

.Proposal-input-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Proposal-input-footer>p {
  margin: 0;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;

  /* identical to box height, or 133% */

  color: #F84239;
}

.Proposal-option-container~.Proposal-input-footer {
  margin-top: -20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.Connect-wallet-btn {
  background: #1969FF;
  border-radius: 8px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;

  /* identical to box height */
  text-align: center;

  /* white */
  color: #FFFFFF;
  border: none;
  padding: 18px 35px;
  border: none;
  cursor: pointer;

  -webkit-animation: delayShow 0s 0.2s forwards;

          animation: delayShow 0s 0.2s forwards;
  visibility: hidden;
}

.Header-account {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #3A4861;
  border-radius: 8px;
  height: 30px;
  padding: 12px 16px 12px 24px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex: none;
          flex: none;
}

.Header-account-avatar-container {
  width: 32px;
  height: 32px;
}

.Header-account-avatar-container {
  position: relative;
}

.Header-account-status {
  position: absolute;
  bottom: 2px;
  right: 0;
}

.Header-account-address {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #EFF3FB;
}

.Header-account-metamask {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* grey */
  color: #B7BECB;
  text-align: left;
}

.Header-account-dropdown {
  margin-left: 8px;
  margin-bottom: 1px;
}

.Header-account-address-container {
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .Proposal-container {
    margin: 0 60px;
  }

  .Proposal-description-container,
  .Proposal-threshold-container {
    margin: 60px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .headerContainer {
    margin-left: 20px;
    margin-right: 10px;
  }

  .Proposal-container {
    margin: 0 10px;
  }

  .Proposal-description-container,
  .Proposal-threshold-container {
    margin: 60px 15px;
  }

  .Proposal-create-btn {
    padding: 10px 20px;
  }

  .Header-account {
    padding: 8px 8px 8px 10px;
  }

  .Template-select-button {
    padding: 10px;
  }

  .Template-container {
    margin-top: 50px;
  }
}

@media screen and (max-width: 576px) {
  .Header-account-metamask {
    display: none;
  }

  .Header-account-address>span {
    display: none;
  }

  .Template-card {
    padding: 12px;
  }

  .headerContainer>h1 {
    display: none;
  }
}

/* Mobile */
.Mobile-header-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin: 20px;
  -webkit-align-items: center;
          align-items: center;
}

.Mobile-header-container>a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Mobile-template-selector {
  padding: 20px;
}

.Mobile-template-selector>h1 {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  color: #EFF3FB;
  margin-top: 0;
}

.Mobile-template-selector>h2 {
  font-family: Proxima Nova;
  font-size: 20px;
  line-height: 23px;

  /* identical to box height */
  text-align: center;

  color: #EFF3FB;
  margin-bottom: 30px;
}

.Mobile-template-card {
  background: #0E1D37;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
}

.carousel.carousel-slider {
  padding-bottom: 40px;
}

.carousel .control-dots {
  margin: 0;
}

.Mobile-home-title>h1 {
  font-family: Proxima Nova;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  margin: 0 20px;
  color: #EFF3FB;
}

.Mobile-proposal-container>h2 {
  font-family: Proxima Nova;
  font-size: 20px;
  line-height: 23px;

  /* identical to box height */
  text-align: center;

  color: #EFF3FB;
}

.Mobile-proposal-container>.Proposal-description-container {
  margin: 20px;
}

.Mobile-proposal-container>.Proposal-threshold-container {
  margin: 40px 20px;
}

.Mobile-modal {
  position: fixed;
  top: 50%;
  left: 20px;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #0E1D37;
  border-radius: 8px;
  height: 272px;
  padding: 24px;
  text-align: center;
  outline: none;
}

.Mobile-modal .Modal-proposal-success {
  margin-bottom: 20px;
}

.Mobile-header-container .Connect-wallet-btn {
  padding: 15px 10px;
}

@-webkit-keyframes delayShow {
  to { visibility: visible; }
}

@keyframes delayShow {
  to { visibility: visible; }
}

.Mobile-proposal-container .Proposal-period-container input {
  width: 46px;
}

.Mobile-proposal-container .Proposal-create-btn {
  width: 100%;
  margin-top: 48px;
  min-width: unset;
}

.carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  background: #1969FF;
  opacity: 0.2;
  outline: none;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .Template-selector,
  .Template-card,
  .headerContainer,
  .Header-account-metamask,
  .Header-account-address>span {
    display: none;
  }
  .Proposal-container {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Proposal-description-container {
    margin-top: 0;
    margin-bottom: 0;
  }
  .Proposal-create-btn {
    width: 100%;
    margin-top: 48px;
    min-width: unset;
  }
  .Proposal-container>h2 {
    display: block;
  }
}

.Dropdown-wrapper {
  position: relative;
  font-size: 1.6rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

}

.Dropdown-header {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  cursor: pointer;
  background: #202F49;
  border-radius: 8px;
  border: none;
  padding: 16px;
  outline: none;
  margin-bottom: 2px;
}

.Dropdown-header-title {
  font-family: Proxima Nova;
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.Dropdown-list {
  position: absolute;
  border-radius: 8px;
  z-index: 10;
  width: 100%;
  max-height: 215px;
  font-weight: 300;
  text-align: left;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-list-item {
  background-color: #202F49;
  border: none;
  border-bottom: 2px solid #0A162E;
  padding: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.Overlay {
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  font-family: "proxima-nova", sans-serif;
  transition: 0.2s all;
 /*  position: 'absolute';
  top: '1rem';
  right: '.8rem'; */

}

.Overlay:disabled {
  cursor: not-allowed
} 


@media screen and (min-width: 768px) {
  .Mobile-template-selector,
  .Mobile-template-card,
  .Mobile-proposal-container,
  .Mobile-header-container,
  .Mobile-modal {
    display: none;
  }
}

